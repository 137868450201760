// Auction  Controller

import ApplicationController from './application_controller';

import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import CableReady from "cable_ready";


/* This is the custom StimulusReflex controller for the Bid Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */
  // connect() {
  //   StimulusReflex.register(this);

  //   consumer.subscriptions.create({ channel: "ListChannel", list_id: this.data.get("id") }, {
  //     received: (data) => {
  //       if (data.cableReady) CableReady.perform(data.operations);
  //     },
  //   });

  //   Sortable.create(this.tasksTarget, {
  //     handle: ".incomplete",
  //     onEnd: (event) => this.reorder(event),
  //   });
  // }


  connect() {
    // super.connect();
    StimulusReflex.register(this);
    console.log('auction controller connected');
    console.log(this.data);
    console.log(`opportunity id: ${this.data.get("auction")}`);
    // consumer.subscriptions.create({ channel: "AuctionChannel", opportunity_id: this.data.get("id") }, {
    consumer.subscriptions.create({ channel: "AuctionChannel", opportunity_id: this.data.get("id") }, {

      connected: () => {
        console.log("connected to auction")
        $('.ping-white').addClass('connected')
        console.log(this.data.get("id"))
        var $auctionGrid = $('.auctions');
        $('.auctions').removeClass('hide');
        $('#my-bids').addClass('hide');
        $('#faq').addClass('hide');
        console.log('compile');
        $auctionGrid.isotope({
          // options
          itemSelector: '.auction-isotope-container',
          layoutMode: 'masonry',
          masonry: { columnWidth: '.auction-isotope-container', isFitWidth: true },
          getSortData: {
            title: '[data-title]', // text from querySelector
            category: '[data-category]', // value of attribute
            number: '[data-number] parseInt',
            // featured: '[data-featured] parseInt',
            bids: '[data-bids] parseInt',
            price: '[data-price] parseInt'
          }
        });
        var $auctionGrid = $('.auctions');
        var $bidsGrid = $('.bids-inner-container');
        if ($('#sort-filter').val()) {
          var sortFilters = $('#sort-filter').val().split("-");
          var sortByValue = sortFilters[0];
          var sortAscending = sortFilters[1] == 'ascending';
        } else {
          var sortFilters = [];
          var sortByValue = 'original-order';
          var sortAscending = true;
        }
        console.log('running here now')
        var qsRegex = new RegExp($('#auction_item_search').val(), 'gi');
        var category = $('#category-filter').val();
        var filter = category == "All Categories" ? "*" : `[data-category='${category}']`
        console.log('filter: ' + filter)
        if ($('#auctionitems-selection-tab').hasClass('active') || $('#catalog-tab').hasClass('active')) {
          $('.auctions').removeClass('hide');
          $('#my-bids').addClass('hide');
          $('#auction-list-view').removeClass('hide');
          $('#faq').addClass('hide');
          console.log('filtering')
          console.log(sortByValue)
          console.log('here again')
          $auctionGrid.isotope({
            filter: function () {
              var $this = $(this);
              var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
              var filterResult = filter ? $this.is(filter) : true;
              return searchResult && filterResult;
            },
            sortBy: sortByValue,
            sortAscending: sortAscending
          });
          if (!$auctionGrid.data('isotope').filteredItems.length) {
            $('.auction-message-div').removeClass('hide');
          } else {
            $('.auction-message-div').addClass('hide');
          }
        } else if ($('#my-bids-tab').hasClass('active')) {
          $('#my-bids').removeClass('hide');
          $('.auctions').addClass('hide');
          $('#faq').addClass('hide');
          var $bidsGrid = $('.bids-inner-container');
          $bidsGrid.isotope({
            itemSelector: '.bid-isotope-container',
            layoutMode: 'masonry',
            masonry: { columnWidth: '.bid-isotope-container', isFitWidth: true },
            getSortData: {
              title: '[data-title]', // text from querySelector
              category: '[data-category]', // value of attribute
              number: '[data-number] parseInt',
              bids: '[data-bids] parseInt',
              price: '[data-price] parseInt'
            }
          });
        } else if ($('#faq-tab').hasClass('active')) {
          $('#faq').removeClass('hide');

        };

      },
      received: (data) => {
        console.log('received auction index data');
        console.log(data);
        window.givsum_data = data;
        if (data.cableReady) console.log(data);
        if (data.cableReady) console.log(data.operations);
        if (data.cableReady) {
          CableReady.perform(data.operations);
          console.log(data)
          console.log('auction item')
          var selector = data.operations[0].selector;
          console.log(selector);
          console.log(selector.substring(0, 18));
          console.log(selector.substring(0, 18) == "#my_bid_items_list");
          console.log($(`${selector} .bid_form input[name="current_bidder_id"]`));
          console.log($(`${selector} .bid_form input[name="current_bidder_id"]`).length);
          if ((selector.substring(0, 18) == "#my_bid_items_list") && ($(`${selector} .bid_form input[name="current_bidder_id"]`).length > 0)) {
            console.log(`${selector} .bid_form input[name="current_bidder_id"]`);
            var bidUserId = parseInt($(`${selector} .bid_form input[name="current_bidder_id"]`)[0].value);
            var winningUserIds = $(`${selector} .bid_form input[name="winning_user_ids"]`)[0].value.split(" ").map(x => +x);
            // If current user placed the bid
            if (winningUserIds.includes(window.givsum_user_id)) {
              console.log('same user');
              var auctionItemId = parseInt($(`${selector} .bid_form input[name="auction_item_id"]`)[0].value);


              var opportunityId = $(`${selector} .bid_form input[name="opportunity_id"]`)[0].value;
              var auctionBidAmount = $(`${selector} .bid_form input[name="bid_amount"]`)[0].value;
              var winningUserIds = $(`${selector} .bid_form input[name="winning_user_ids"]`)[0].value.split(" ").map(x => +x);
              if (localStorage.getItem('userBids')) {
                var myBids = JSON.parse(localStorage.getItem('userBids'));
              } else {
                var myBids = []
              }
              var hasOutbids = myBids.filter(function (e) { return e.winning_bid === false; }).length > 0;
              if (document.getElementById("outbid_indicator")) {
                if (hasOutbids) {
                  document.getElementById("outbid_indicator").classList.remove("hide");
                } else {
                  document.getElementById("outbid_indicator").classList.add("hide");
                }
              }
              const onAuctionIndexPage = $('#show_auction.auction_items_page').length > 0;
              if (myBids && onAuctionIndexPage) {
                myBids.forEach(function (bid) {
                  var itemId = bid.store_item_id;
                  var selector = `#items_list_store_item_${itemId} .auction-item`;
                  document.querySelector(selector).classList.add("current-user-has-bid");
                });
              }
              var myBidsMap = JSON.parse(localStorage.getItem('userBidsMap'));

              var myLastBid = parseFloat(myBidsMap[auctionItemId].amount.replace(',', '')).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
              $($(`${selector} .bidder_is_current_winner .bid-amount .value`)[0]).text(myLastBid)
              // Show for the current user
              console.log('current user is winning bider')
              $(`${selector} .bidder_is_current_winner`).removeClass('hide');
            } else {
              var myBidsMap = JSON.parse(localStorage.getItem('userBidsMap'));
              // If other user placed the bid
              // Show my last bid amount
              var auctionItemId = parseInt($(`${selector} .bid_form input[name="auction_item_id"]`)[0].value)
              var winningUserIds = $(`${selector} .bid_form input[name="winning_user_ids"]`)[0].value.split(" ").map(x => +x);
              var myLastBid = parseFloat(myBidsMap[auctionItemId].amount.replace(',', '')).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
              console.log('replace value');
              console.log(`${selector} .bidder_is_not_current_winner .bid-amount .value`);
              $($(`${selector} .bidder_is_not_current_winner .bid-amount .value`)[0]).text(myLastBid)
              // Show form for other user was bidder
              $(`${selector} .bidder_is_not_current_winner`).removeClass('hide');
            }
          }
        }
        //
        const onAuctionIndexPage = $('#show_auction.auction_items_page').length > 0;
        console.log('onAuctionIndexPage')
        console.log(onAuctionIndexPage)
        var myBids = JSON.parse(localStorage.getItem('userBids'));
        var hasOutbids = myBids.filter(function (e) { return e.winning_bid === false; }).length > 0;
        if (document.getElementById("outbid_indicator")) {
          if (hasOutbids) {
            document.getElementById("outbid_indicator").classList.remove("hide");
          } else {
            document.getElementById("outbid_indicator").classList.add("hide");
          }
        }
        console.log('updating here')
        if (myBids && onAuctionIndexPage) {
          myBids.forEach(function (bid) {
            var itemId = bid.store_item_id;
            var selector = `#items_list_store_item_${itemId} .auction-item`;
            document.querySelector(selector).classList.add("current-user-has-bid");
          });
        }
      },
    });
    // add your code here, if applicable
  }
  submitFilterForm() {
    console.log('form')
    // document.getElementById('filter_form').submit();
    var $auctionGrid = $('.auctions');
    var $bidsGrid = $('.bids-inner-container');
    if ($('#sort-filter').val()) {
      var sortFilters = $('#sort-filter').val().split("-");
      var sortByValue = sortFilters[0];
      var sortAscending = sortFilters[1] == 'ascending';
    } else {
      var sortFilters = [];
      var sortByValue = 'number';
      var sortAscending = true;
    }
    var qsRegex = new RegExp($('#auction_item_search').val(), 'gi');
    var category = $('#category-filter').val();
    var filter = category == "All Categories" ? "*" : `[data-category='${category}']`
    if ($('#auctionitems-selection-tab').hasClass('active') || $('#catalog-tab').hasClass('active')) {
      $('.auctions').removeClass('hide');
      $('#my-bids').addClass('hide');
      $('#auction-list-view').removeClass('hide');
      $('#faq').addClass('hide');
      $auctionGrid.isotope({
        filter: function () {
          var $this = $(this);
          var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
          var filterResult = filter ? $this.is(filter) : true;
          return searchResult && filterResult;
        },
        sortBy: sortByValue,
        sortAscending: sortAscending
      });
      if (!$auctionGrid.data('isotope').filteredItems.length) {
        $('.auction-message-div').removeClass('hide');
      } else {
        $('.auction-message-div').addClass('hide');
      }
    } else if ($('#my-bids-tab').hasClass('active')) {
      $('#my-bids').removeClass('hide');
      $('.auctions').addClass('hide');
      $('#auction-list-view').removeClass('hide');
      $('#faq').addClass('hide');
      $bidsGrid.isotope({
        itemSelector: '.bid-isotope-container',
        layoutMode: 'masonry',
        masonry: { columnWidth: '.bid-isotope-container', isFitWidth: true },
        filter: function () {
          var $this = $(this);
          var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
          var filterResult = filter ? $this.is(filter) : true;
          return searchResult && filterResult;
        },
        sortBy: sortByValue,
        sortAscending: sortAscending
      });
      if (!$bidsGrid.data('isotope').filteredItems.length) {
        $('.my-bids-message-div').removeClass('hide');
      } else {
        $('.my-bids-message-div').addClass('hide');
      }
    } else {
      $('#my-bids').addClass('hide');
      $('.auctions').addClass('hide');
      $('#auction-list-view').addClass('hide'); // hide the search, sort, and filter bars
      $('#faq').removeClass('hide');
    };


  }

  submitFilterFormWithDebounce() {
    clearTimeout(this.timeout)
    console.log('debouncing');
    var $auctionGrid = $('.auctions');
    var $bidsGrid = $('.bids-inner-container');
    this.timeout = setTimeout(() => {
      if ($('#sort-filter').val()) {
        var sortFilters = $('#sort-filter').val().split("-");
        var sortByValue = sortFilters[0];
        var sortAscending = sortFilters[1] == 'ascending';
      } else {
        var sortFilters = [];
        var sortByValue = 'number';
        var sortAscending = true;
      }
      var qsRegex = new RegExp($('#auction_item_search').val(), 'gi');
      var category = $('#category-filter').val();
      var filter = category == "All Categories" ? "*" : `[data-category='${category}']`
      if ($('#auctionitems-selection-tab').hasClass('active') || $('#catalog-tab').hasClass('active')) {
        $('.auctions').removeClass('hide');
        $('#my-bids').addClass('hide');
        $auctionGrid.isotope({
          filter: function () {
            var $this = $(this);
            var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
            var filterResult = filter ? $this.is(filter) : true;
            return searchResult && filterResult;
          },
          sortBy: sortByValue,
          sortAscending: sortAscending
        });
        if (!$auctionGrid.data('isotope').filteredItems.length) {
          $('.auction-message-div').removeClass('hide');
        } else {
          $('.auction-message-div').addClass('hide');
        }
      } else {
        $('#my-bids').removeClass('hide');
        $('.auctions').addClass('hide');
        $bidsGrid.isotope({
          itemSelector: '.bid-isotope-container',
          layoutMode: 'masonry',
          masonry: { columnWidth: '.bid-isotope-container', isFitWidth: true },
          filter: function () {
            var $this = $(this);
            var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
            var filterResult = filter ? $this.is(filter) : true;
            return searchResult && filterResult;
          },
          sortBy: sortByValue,
          sortAscending: sortAscending
        });
        if (!$bidsGrid.data('isotope').filteredItems.length) {
          $('.my-bids-message-div').removeClass('hide');
        } else {
          $('.my-bids-message-div').addClass('hide');
        }
      };

    }, 300);
  }

  clearAndSubmitFilterForm() {
    document.getElementById('auction_item_search').value = '';
    var $auctionGrid = $('.auctions');
    var $bidsGrid = $('.bids-inner-container');
    if ($('#sort-filter').val()) {
      var sortFilters = $('#sort-filter').val().split("-");
      var sortByValue = sortFilters[0];
      var sortAscending = sortFilters[1] == 'ascending';
    } else {
      var sortFilters = [];
      var sortByValue = 'number';
      var sortAscending = true;
    }
    var qsRegex = new RegExp($('#auction_item_search').val(), 'gi');
    var category = $('#category-filter').val();
    var filter = category == "All Categories" ? "*" : `[data-category='${category}']`
    if ($('#auctionitems-selection-tab').hasClass('active') || $('#catalog-tab').hasClass('active')) {
      $('.auctions').removeClass('hide');
      $('#my-bids').addClass('hide');
      $auctionGrid.isotope({
        filter: function () {
          var $this = $(this);
          var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
          var filterResult = filter ? $this.is(filter) : true;
          return searchResult && filterResult;
        },
        sortBy: sortByValue,
        sortAscending: sortAscending
      });
      if (!$auctionGrid.data('isotope').filteredItems.length) {
        $('.auction-message-div').removeClass('hide');
      } else {
        $('.auction-message-div').addClass('hide');
      }
    } else {
      $('#my-bids').removeClass('hide');
      $('.auctions').addClass('hide');
      $bidsGrid.isotope({
        itemSelector: '.bid-isotope-container',
        layoutMode: 'masonry',
        masonry: { columnWidth: '.bid-isotope-container', isFitWidth: true },
        filter: function () {
          var $this = $(this);
          var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
          var filterResult = filter ? $this.is(filter) : true;
          return searchResult && filterResult;
        },
        sortBy: sortByValue,
        sortAscending: sortAscending
      });
      if (!$bidsGrid.data('isotope').filteredItems.length) {
        $('.my-bids-message-div').removeClass('hide');
      } else {
        $('.my-bids-message-div').addClass('hide');
      }
    };

  }
}

/* Reflex specific lifecycle methods.
 *
 * For every method defined in your Reflex class, a matching set of lifecycle methods become available
 * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
 * need them.
 *
 * Important:
 * Make sure to add data-controller="bid" to your markup alongside
 * data-reflex="Bid#dance" for the lifecycle methods to fire properly.
 *
 * Example:
 *
 *   <a href="#" data-reflex="click->Bid#dance" data-controller="bid">Dance!</a>
 *
 * Arguments:
 *
 *   element - the element that triggered the reflex
 *             may be different than the Stimulus controller's this.element
 *
 *   reflex - the name of the reflex e.g. "Bid#dance"
 *
 *   error/noop - the error message (for reflexError), otherwise null
 *
 *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
 */

// Assuming you create a "Bid#dance" action in your Reflex class
// you'll be able to use the following lifecycle methods:

// beforeDance(element, reflex, noop, reflexId) {
//  element.innerText = 'Putting dance shoes on...'
// }

// danceSuccess(element, reflex, noop, reflexId) {
//   element.innerText = '\nDanced like no one was watching! Was someone watching?'
// }

// danceError(element, reflex, error, reflexId) {
//   console.error('danceError', error);
//   element.innerText = "\nCouldn\'t dance!"
// }

// afterDance(element, reflex, noop, reflexId) {
//   element.innerText = '\nWhatever that was, it\'s over now.'
// }

// finalizeDance(element, reflex, noop, reflexId) {
//   element.innerText = '\nNow, the cleanup can begin!'
// }
//}
